import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { getValidationSchemaEmail } from "../components/validations"
import { Formik, Form, Field, ErrorMessage } from "formik"
import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import InputLabel from "@material-ui/core/InputLabel"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import CircularProgress from '@material-ui/core/CircularProgress';
import AlertDialog from '../components/dialog'
import { forgotPassword as forgotPasswordAction, finishForgotPassword } from '../state/store'
import { useSelector, useDispatch } from 'react-redux'


const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      logoImageTrade: file(relativePath: { eq: "cedar-logo-trademark.png" }) {
        childImageSharp {
          fixed(width: 120) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return <Img fixed={data.logoImageTrade.childImageSharp.fixed} />
}

const ForgotForm = ({ loginResult, submitting }) => {
  return (
    <Formik
      validationSchema={getValidationSchemaEmail}
      initialValues={{
        email: "",
        password: "",
      }}
      onSubmit={(values, actions) => {
        loginResult(values)
        actions.setSubmitting(false)
      }}
    >
      {({
        errors,
        isSubmitting,
      }) => (
        <Form>
          <Field
            type="email"
            name="email"
            render={({ field, form }) => (
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel htmlFor="email">Email</InputLabel>
                <OutlinedInput
                  id="email"
                  {...field}
                  error={
                    form.errors[field.name] && form.touched[field.name]
                      ? true
                      : false
                  }
                  autoComplete="email"
                  // autoFocus
                  labelWidth={50}
                  required
                />
                <ErrorMessage name="email">
                  {msg => <FormHelperText>{msg}</FormHelperText>}
                </ErrorMessage>
              </FormControl>
            )}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={
              isSubmitting || Object.keys(errors).length > 0 || submitting
            }
            type="submit"
          >
            Reset Password
          </Button>
        </Form>
      )}
    </Formik>
  )
}

const Forgot = () => {
  const {forgotPassword}  = useSelector(state=>state.db)
  const dispatch = useDispatch()

  const classes = useStyles()
  return (
    <Layout>
      <SEO title="Forgot Password" />
    <Container component="main" maxWidth="xs">
    {forgotPassword && forgotPassword.error && <AlertDialog title="Error" message={forgotPassword.error.msg} closeDialog={()=>dispatch(finishForgotPassword({loading: false, msg: null, error: null}))}/>}
    {forgotPassword && forgotPassword.msg && <AlertDialog title="Success" message={forgotPassword.msg} closeDialog={()=>dispatch(finishForgotPassword({loading: false, msg: null, error: null}))}/>}
      {forgotPassword && forgotPassword.loading && <CircularProgress/>}
      <div className={classes.paper}>
        <Image />
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <ForgotForm loginResult={({email})=>dispatch(forgotPasswordAction(email))}/>
      </div>
    </Container>
    </Layout>
  )
}

export default Forgot
